import React from 'react';
import { motion } from 'framer-motion';

import { mediaLinks } from '../../configs/socialMedia';

import styles from './Contacts.module.scss';

import variants from './Contacts.variants';
import { AnimatedImage } from '../AnimatedImage';

function Contacts() {
  return (
    <div
      id={styles.socialMediaLinks}
      className="hstack justify-content-between justify-content-xxl-around px-xxl-4"
    >
      {mediaLinks.map(({ name, href, icon, animatedIcon, tooltip }) => (
        <motion.a
          key={name}
          href={href}
          target="_blank"
          className="link-light"
          data-bs-toggle="tooltip"
          data-bs-title={tooltip}
          whileHover="hover"
          variants={variants.growUp}
          layoutId={name}
        >
          <AnimatedImage
            initial={icon}
            animation={animatedIcon}
            className={styles.imageSize}
          />
        </motion.a>
      ))}
    </div>
  );
}

export default Contacts;
