import React from 'react';
import { motion } from 'framer-motion';

import { useGames } from '../../contexts';
import variants from './GamesGallery.variants';

function GamesGallery() {
  const { games } = useGames();

  return (
    <div>
      <h1 className="fs-1 mb-3 bg-secondary text-light p-2">Games</h1>

      <div className="row justify-content-around">
        {games.map(({ name, cover, link }) => (
          <div key={name} className="col-12 col-sm-6 col-lg-4">
            <a href={link} target="_blank" rel="noreferrer">
              <figure className="figure w-100">
                <motion.img
                  src={cover}
                  alt={name}
                  className="w-100 pe-auto"
                  initial="initial"
                  whileHover="hover"
                  variants={variants.highlight}
                />
                <figcaption className="figure-caption fs-1 text-center text-secondary">
                  {name}
                </figcaption>
              </figure>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GamesGallery;
