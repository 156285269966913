import React from 'react';
import { useGames } from '../../contexts';

function JamsList() {
  const { jams } = useGames();

  return (
    <div>
      <h1 className="fs-1 mb-3 bg-secondary text-light p-2">Jams</h1>

      <div className="row gy-3">
        {jams.map(({ name, link }) => (
          <div key={name} className="col-6 col-lg-4">
            <p className="fs-3 text-center">
              <a href={link} target="_blank" rel="noreferrer">
                {name}
              </a>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default JamsList;
