import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { NavbarLinkData } from './Navbar.types';

import './Navbar.styles.scss';

function Navbar() {
  const { pathname: currentLocation } = useLocation();

  const getStatusClass = useCallback(
    (linkHref: string) => (linkHref === currentLocation ? 'active' : ''),
    [currentLocation]
  );

  const links: NavbarLinkData[] = useMemo(
    () => [
      {
        name: 'Home',
        href: '/',
        get statusClass() {
          return getStatusClass(this.href);
        },
      },
      {
        name: 'Portfolio',
        href: '/portfolio',
        get statusClass() {
          return getStatusClass(this.href);
        },
      },
      {
        name: 'Games',
        href: '/games',
        get statusClass() {
          return getStatusClass(this.href);
        },
      },
      {
        name: 'About',
        href: '/about',
        get statusClass() {
          return getStatusClass(this.href);
        },
      },
    ],
    [getStatusClass]
  );

  return (
    <nav className="navbar navbar-expand navbar-dark bg-primary">
      <div className="container-fluid justify-content-center">
        <ul className="navbar-nav">
          {links.map(({ name, href, statusClass }) => {
            return (
              <li key={href} className="nav-item px-3 pt-3">
                <Link to={href} className={`nav-link fs-5 ${statusClass}`}>
                  {name.toUpperCase()}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
