import { Variants } from 'framer-motion';

const bounce: Variants = {
  animate: {
    y: 15,
    transition: {
      repeat: Infinity,
      repeatType: 'reverse',
      ease: 'easeInOut',
      duration: 1,
    },
  },
};

const smile: Variants = {
  hover: {
    opacity: 0,
  },
};

export default {
  bounce,
  smile,
};
