import React, { useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';

import { useArtGalleryScroll, useImageViewerControls } from '../../contexts';
import { ImageCardProps } from './ImageCard.types';

import variants from './ImageCard.variants';

function ImageCard({ image }: ImageCardProps) {
  const { updateImage, toggleDetailDisplay } = useImageViewerControls();
  const { saveScrollPosition } = useArtGalleryScroll();

  const calcColumnSize = useCallback(
    (baseSize: number) =>
      Math.min(12, Math.max(1, Math.round(baseSize * image.proportion))),
    [image.proportion]
  );

  const sizings = useMemo(() => {
    const baseImageSize = 100;

    return {
      columns: `col-${calcColumnSize(4)} col-md-${calcColumnSize(
        3
      )} col-lg-${calcColumnSize(2)}`,
      width: baseImageSize * image.proportion,
      height: baseImageSize,
    };
  }, [image.proportion, calcColumnSize]);

  const handleClick = useCallback(() => {
    updateImage(image);
    saveScrollPosition();
    toggleDetailDisplay();
  }, [image, toggleDetailDisplay, saveScrollPosition, updateImage]);

  return (
    <motion.div
      className={sizings.columns}
      onClick={handleClick}
      layoutId={image.url}
    >
      <motion.figure
        className="d-flex justify-content-center bg-light overflow-hidden mb-0"
        initial="initial"
        whileHover="hover"
        variants={variants.border}
      >
        <img
          src={image.url}
          alt={image.fileName}
          width={sizings.width}
          height={sizings.height}
        />
      </motion.figure>
    </motion.div>
  );
}

export default ImageCard;
