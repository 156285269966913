import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ThayAngry, ThayHappy, ThaySad, ThaySmile } from '../../images';
import { SlideshowImageOption } from './SlideshowImage.types';

function SlideshowImage() {
  const allImages: SlideshowImageOption[] = useMemo(
    () => [
      { image: ThayHappy, timeout: 1000 },
      { image: ThaySmile, timeout: 1000 },
      { image: ThayAngry, timeout: 1000 },
      { image: ThaySad, timeout: 1000 },
    ],
    []
  );

  const [imageIndex, setImageIndex] = useState(0);
  const [imageOption, setImageOption] = useState<SlideshowImageOption>(
    allImages[0]
  );

  const showNextImage = useCallback(() => {
    const nextIndex = (imageIndex + 1) % allImages.length;
    const nextImage = allImages[nextIndex];

    setImageIndex(nextIndex);
    setImageOption(nextImage);
  }, [allImages, imageIndex, setImageIndex, setImageOption]);

  useEffect(() => {
    setTimeout(showNextImage, imageOption.timeout);
  }, [showNextImage, imageOption]);

  return (
    <img
      src={imageOption.image}
      alt="Thayson"
      width={250}
      height={250}
      className="figure-img img-fluid mb-0"
    />
  );
}

export default SlideshowImage;
