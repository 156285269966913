import React from 'react';
import { motion } from 'framer-motion';

import { SlideshowImage } from '../SlideshowImage';
import variants from './BouncingAvatar.variants';

function BouncingAvatar() {
  return (
    <motion.figure
      className="figure mb-0"
      initial="initial"
      animate="animate"
      variants={variants.bounce}
    >
      <div className="row">
        <div className="col-6 col-xxl-12 hstack justify-content-center position-relative px-0">
          <SlideshowImage />
        </div>

        <div className="col-6 col-xxl-12 vstack justify-content-center">
          <figcaption className="figure-caption text-center fs-1 text-bg-primary fw-semibold">
            Hi, I&apos;m Thayson!
          </figcaption>
        </div>
      </div>
    </motion.figure>
  );
}

export default BouncingAvatar;
