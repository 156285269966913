import React, { useEffect } from 'react';
import { ImageCard } from '../ImageCard';
import { useArt, useArtGalleryScroll } from '../../contexts';

function ArtGallery() {
  const { arts } = useArt();
  const { artGalleryRef, applySavedScrollPosition } = useArtGalleryScroll();

  useEffect(() => {
    applySavedScrollPosition();
  }, [applySavedScrollPosition]);

  return (
    <div
      ref={artGalleryRef}
      className="container-fluid flex-grow-1 overflow-auto bg-dark bg-opacity-25 p-2"
    >
      <div className="vstack gap-1">
        {arts.map(({ group, folder, images }) => (
          <div key={folder}>
            <h1 className="fs-2 mb-0 bg-secondary text-light p-2 mb-2">
              {group}
            </h1>

            <div className="row g-1">
              {images.map((image, index) => (
                <ImageCard key={index} image={image} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ArtGallery;
