import { Variants } from 'framer-motion';

const highlight: Variants = {
  initial: {
    borderStyle: 'solid',
    borderWidth: '7px',
  },
  hover: {
    borderColor: ['#2E1760', '#ffffff'],
    transition: {
      repeat: Infinity,
      repeatType: 'reverse',
      ease: 'linear',
      duration: 0.75,
    },
  },
};

export default {
  highlight,
};
