import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { LinkData } from './MainLinks.types';

function MainLinks() {
  const links: LinkData[] = useMemo(
    () => [
      {
        name: 'Portfolio',
        href: '/portfolio',
      },
      {
        name: 'Games',
        href: '/games',
      },
      {
        name: 'About',
        href: '/about',
      },
    ],
    []
  );

  return (
    <ul className="nav flex-column fs-1 text-center text-uppercase fw-semibold">
      {links.map(({ name, href }, index) => (
        <li key={index} className="nav-item my-0">
          <Link to={href} className="nav-link link-light">
            {name}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default MainLinks;
