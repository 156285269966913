import { Variants } from 'framer-motion';

const slideIn: Variants = {
  initial: {
    y: 1000,
  },
  animate: {
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const darken: Variants = {
  initial: {
    backgroundColor: 'var(--bs-white)',
  },
  animate: {
    backgroundColor: 'var(--bs-gray-700)',
  },
};

const mouseHover: Variants = {
  initial: {
    opacity: 0.8,
    height: 48,
  },
  hover: {
    opacity: 1,
    height: 64,
  },
};

export default {
  slideIn,
  darken,
  mouseHover,
};
