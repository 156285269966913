import { motion } from 'framer-motion';
import React, { useEffect } from 'react';

import {
  BigHeader,
  GamesGallery,
  Header,
  JamsList,
  Navbar,
  VerticalContacts,
} from '../../components';
import { initTooltips } from '../../utils';

function GamesPortfolio() {
  useEffect(() => initTooltips(), []);

  return (
    <div className="container-fluid px-0">
      <div className="row gx-0">
        <motion.div
          className="col-lg-3 col-xl-2 d-none d-lg-block text-bg-primary"
          layoutId="sidebar"
        >
          <div className="vstack align-items-stretch vh-100 pt-3 pb-4">
            <BigHeader />

            <VerticalContacts />
          </div>
        </motion.div>
        <div className="col-12 col-lg-9 col-xl-10">
          <div className="vstack vh-100">
            <div className="text-bg-primary d-lg-none py-3">
              <Header />
            </div>

            <Navbar />

            <div className="container-fluid py-3 overflow-auto flex-grow-1">
              <GamesGallery />
              <JamsList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GamesPortfolio;
