import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { animate, motion, useMotionValue } from 'framer-motion';

import { useImageViewerControls } from '../../contexts';

import styles from './ImageViewer.module.scss';
import variants from './ImageViewer.variants';
import { CloseButton } from '../../images';

function ImageViewer() {
  const zoom = useMotionValue(1);
  const [displayZoom, setDisplayZoom] = useState(zoom.get() * 100);
  const { currentImage, toggleDetailDisplay } = useImageViewerControls();
  const baseSize = useMemo(() => 300, []);
  const zoomSliderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!zoomSliderRef.current) return;
    zoomSliderRef.current.value = zoom.get().toString();
  }, [zoom]);

  const handleClick = useCallback(
    () => toggleDetailDisplay(),
    [toggleDetailDisplay]
  );

  const handleZoomChange = useCallback(
    ({ target: { value: rawValue } }: ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(rawValue);

      animate(zoom, value);
      setDisplayZoom(Math.round(value * 100));
    },
    [zoom]
  );

  return (
    <motion.div
      className="container-fluid overflow-hidden flex-grow-1 p-0"
      layoutId={currentImage?.url}
    >
      <motion.div
        className="vstack justify-content-between h-100"
        initial="initial"
        animate="animate"
        variants={variants.darken}
      >
        <div className="hstack align-items-center p-4">
          {currentImage?.description && (
            <h1 className="text-light fw-semibold fst-italic font-monospace fs-1 mb-0">
              {currentImage?.description}
            </h1>
          )}
          <button
            id={styles.closeButton}
            className="btn ms-auto p-0"
            onClick={handleClick}
          >
            <motion.img
              className="pe-auto"
              src={CloseButton}
              initial="initial"
              whileHover="hover"
              variants={variants.mouseHover}
            />
          </button>
        </div>

        <div className="d-flex align-items-center overflow-hidden flex-grow-1">
          {currentImage && (
            <motion.img
              id="image"
              className="d-block mx-auto"
              src={currentImage.url}
              alt={currentImage.description}
              width={baseSize * currentImage.proportion}
              height={baseSize}
              style={{ scale: zoom }}
            />
          )}
        </div>

        <motion.div
          className="text-bg-secondary d-flex justify-content-center"
          initial="initial"
          animate="animate"
          variants={variants.slideIn}
        >
          <div className="col-6 col-xxl-4 my-4">
            <label
              htmlFor="zoom"
              className="form-label fs-2 hstack justify-content-between"
            >
              <span>ZOOM</span>
              <span>{displayZoom}%</span>
            </label>

            <input
              type="range"
              ref={zoomSliderRef}
              min={0.1}
              max={5}
              step={0.1}
              className="form-range"
              onChange={handleZoomChange}
            />
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default ImageViewer;
