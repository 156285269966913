import { motion } from 'framer-motion';
import React, { useEffect } from 'react';

import { AboutContent, Contacts, Header, Navbar } from '../../components';
import { initTooltips } from '../../utils';

function About() {
  useEffect(() => initTooltips(), []);

  return (
    <div className="container-fluid px-0">
      <div className="row gx-0">
        <motion.div
          className="col-12 col-lg-6 text-bg-primary"
          layoutId="sidebar"
        >
          <div className="vstack vh-100 p-4">
            <Header />

            <AboutContent />

            <Contacts />
          </div>
        </motion.div>
        <div id="side-background" className="col-6 d-none d-lg-block pixelated">
          <Navbar />
        </div>
      </div>
    </div>
  );
}

export default About;
