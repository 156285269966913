import React from 'react';
import { Link } from 'react-router-dom';

import { ThayAstra } from '../../images';
import styles from './BigHeader.module.scss';

function BigHeader() {
  return (
    <div className="hstack gap-3 justify-content-center px-3">
      <img
        id={styles.thaysonAstraMode}
        src={ThayAstra}
        alt="Thayson's Astral Mode"
      />

      <div className="lh-1">
        <Link to="/" className="link-light">
          <h1 className="fs-2">Thay2son</h1>
          <p className="m-0 fs-5 text-center">Pixel Artist</p>
        </Link>
      </div>
    </div>
  );
}

export default BigHeader;
