import React from 'react';
import { motion } from 'framer-motion';

import globalVariants from '../../animations/globals';

function IntroContent() {
  return (
    <motion.div
      className="fs-3 lh-sm"
      initial="initial"
      animate="animate"
      variants={globalVariants.fadeIn}
    >
      <p>Here’s a little bit of the pixel art styles I do. </p>
      <p>
        If you have any questions about my work or if you want to hire me, here
        are some ways to contact:
      </p>
    </motion.div>
  );
}

export default IntroContent;
