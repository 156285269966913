import { Tooltip } from 'bootstrap';

export function initTooltips() {
  const tooltipElements = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipElementsArray = Array.from(tooltipElements);

  tooltipElementsArray.map(
    (element) => new Tooltip(element, { offset: [0, 10], placement: 'top' })
  );
}
