import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

import { ArtGalleryScrollContextData } from './ArtGalleryScroll.types';

const ArtGalleryScrollContext = createContext<ArtGalleryScrollContextData>({
  saveScrollPosition: () => null,
  applySavedScrollPosition: () => null,
});

const SCROLL_KEY = 'artGalleryScrollPosition';

function ArtGalleryScrollProvider({ children }: PropsWithChildren) {
  const artGalleryRef = useRef<HTMLDivElement>(null);

  const saveScrollPosition = useCallback(() => {
    const scrollPosition = artGalleryRef.current?.scrollTop;
    if (!scrollPosition) return;

    sessionStorage.setItem(SCROLL_KEY, scrollPosition.toString());
  }, []);

  const applySavedScrollPosition = useCallback(() => {
    const fetchedKey = sessionStorage.getItem(SCROLL_KEY);
    if (!fetchedKey || !artGalleryRef.current) return;

    artGalleryRef.current.scrollTop = parseInt(fetchedKey);
  }, []);

  const value: ArtGalleryScrollContextData = useMemo(
    () => ({ artGalleryRef, saveScrollPosition, applySavedScrollPosition }),
    [saveScrollPosition, applySavedScrollPosition]
  );

  return (
    <ArtGalleryScrollContext.Provider value={value}>
      {children}
    </ArtGalleryScrollContext.Provider>
  );
}

const useArtGalleryScroll = () => {
  const context = useContext(ArtGalleryScrollContext);

  if (!context) throw 'ArtGalleryScrollProvider is not available';

  return context;
};

export { ArtGalleryScrollProvider, useArtGalleryScroll };
