import React from 'react';
import { motion } from 'framer-motion';

import styles from './AboutContent.module.scss';
import globalVariants from '../../animations/globals';
import { Link } from 'react-router-dom';

function AboutContent() {
  return (
    <motion.div
      id={styles.aboutText}
      className="lh-sm flex-grow-1 vstack"
      initial="initial"
      animate="animate"
      variants={globalVariants.fadeIn}
    >
      <div className="my-auto">
        <p className="mb-0">{'///'}</p>
        <p className="mb-0"> hello, my name is Thayson!!</p>
        <p className="mb-0">
          i&apos;m brazilian and i&apos;ve been working with pixel art for 5
          years
        </p>
        <p className="mb-0">
          i&apos;m specialized in character animation, but I&apos;ve also make
          sets (platform and isometric)
        </p>
        <p>
          my favorite games are <em>Enter the Gungeon</em> and{' '}
          <em>Blasphemous</em>
        </p>

        <p className="mb-0">
          i became a pixel artist after dropping out of several courses
        </p>
        <p className="mb-0">(engineering/programming/security).</p>
        <p>
          it seems that playing old games and drawing pictures my whole life
          prepared me well for making games.
        </p>

        <p className="mb-0">
          if you like my arts, contact me and let&apos;s make a game together!
        </p>
        <p>{'///'}</p>
      </div>

      <div className="d-lg-none">
        <p className="text-center">
          <Link className="link-light" to="/">
            ← Back to Home
          </Link>
        </p>
      </div>
    </motion.div>
  );
}

export default AboutContent;
