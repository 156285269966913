import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ArtContextData, GroupArt } from './Art.types';
import { artsDataSource, imagesPath } from '../../configs/image';
import { ArtData } from '../../types/arts';

const ArtContext = createContext<ArtContextData>({
  arts: [],
});

function ArtProvider({ children }: PropsWithChildren) {
  const [arts, setArts] = useState<GroupArt[]>([]);

  function processImage(image: ArtData, folder: string) {
    const url = `${imagesPath}/${folder}/${image.fileName}`;
    const proportion = image.proportion ?? 1;
    return {
      ...image,
      url,
      proportion,
    };
  }

  useEffect(() => {
    async function getImageData() {
      const response = await fetch(artsDataSource);

      try {
        const rawData: GroupArt[] = await response.json();

        const fetchedData = rawData.map((groupData) => ({
          ...groupData,
          images: groupData.images.map((image) =>
            processImage(image, groupData.folder)
          ),
        }));

        setArts(fetchedData);
      } catch {
        setArts([]);
      }
    }

    getImageData();
  }, []);

  const value: ArtContextData = useMemo(() => ({ arts }), [arts]);

  return <ArtContext.Provider value={value}>{children}</ArtContext.Provider>;
}

const useArt = () => {
  const context = useContext(ArtContext);

  if (!context) throw 'ArtProvider is not available';

  return context;
};

export { ArtProvider, useArt };
