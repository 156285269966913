import React from 'react';
import { motion } from 'framer-motion';

import styles from './VerticalContacts.module.scss';
import variants from './VerticalContacts.variants';
import { mediaLinks } from '../../configs/socialMedia';
import { AnimatedImage } from '../AnimatedImage';

function VerticalContacts() {
  return (
    <div
      id={styles.socialMediaLinks}
      className="vstack justify-content-around align-items-center flex-grow-1"
    >
      {mediaLinks.map(({ name, href, icon, animatedIcon, tooltip }) => (
        <motion.a
          key={name}
          href={href}
          target="_blank"
          className="link-light"
          data-bs-toggle="tooltip"
          data-bs-title={tooltip}
          whileHover="hover"
          variants={variants.growUp}
          layoutId={name}
        >
          <AnimatedImage
            initial={icon}
            animation={animatedIcon}
            alt={name}
            className={styles.imageSize}
          />
        </motion.a>
      ))}
    </div>
  );
}

export default VerticalContacts;
