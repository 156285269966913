import { Variants } from 'framer-motion';

const fadeIn: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: 0.75,
    },
  },
};

export default {
  fadeIn,
};
