import React, { useCallback, useRef } from 'react';

import { AnimatedImageProps } from './AnimatedImage.types';

function AnimatedImage({
  initial,
  animation,
  size,
  className,
  alt,
}: AnimatedImageProps) {
  const imageRef = useRef<HTMLImageElement>(null);

  const handleHoverStart = useCallback(() => {
    if (!imageRef.current) return;
    imageRef.current.src = animation;
  }, [animation]);

  const handleHoverEnd = useCallback(() => {
    if (!imageRef.current) return;
    imageRef.current.src = initial;
  }, [initial]);

  return (
    <div onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd}>
      <img
        ref={imageRef}
        className={className}
        src={initial}
        width={size}
        height={size}
        alt={alt}
      />
    </div>
  );
}

export default AnimatedImage;
