import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { GameData, GamesContextData, JamData } from './Games.types';
import { gameImagesPath, gamesDataSource } from '../../configs/image';

const GamesContext = createContext<GamesContextData>({
  games: [],
  jams: [],
});

function GamesProvider({ children }: PropsWithChildren) {
  const [games, setGames] = useState<GameData[]>([]);
  const [jams, setJams] = useState<JamData[]>([]);

  useEffect(() => {
    async function getGamesData() {
      const response = await fetch(gamesDataSource);

      try {
        const { games: rawGames, jams: rawJams }: GamesContextData =
          await response.json();

        const fetchedGames = rawGames.map((game) => ({
          ...game,
          cover: `${gameImagesPath}/${game.cover}`,
        }));

        const fetchedJams = rawJams;

        setGames(fetchedGames);
        setJams(fetchedJams);
      } catch {
        setGames([]);
        setJams([]);
      }
    }

    getGamesData();
  }, []);

  const value: GamesContextData = useMemo(
    () => ({ games, jams }),
    [games, jams]
  );

  return (
    <GamesContext.Provider value={value}>{children}</GamesContext.Provider>
  );
}

const useGames = () => {
  const context = useContext(GamesContext);

  if (!context) throw 'GamesProvider is not available';

  return context;
};

export { GamesProvider, useGames };
