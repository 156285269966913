import { Variants } from 'framer-motion';

const border: Variants = {
  initial: {
    borderStyle: 'solid',
    borderColor: 'var(--bs-gray-400)',
  },
  hover: {
    borderColor: 'var(--bs-secondary)',
  },
};
export default {
  border,
};
