import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ImageViewerControlsData } from './ImageViewerControls.types';
import { ArtData } from '../../types/arts';

const ImageViewerControlsContext = createContext<
  ImageViewerControlsData | undefined
>(undefined);

function ImageViewerControlsProvider({ children }: PropsWithChildren) {
  const [isShown, setIsShown] = useState(false);
  const [currentImage, setCurrentImage] = useState<ArtData>();

  const toggleDetailDisplay = useCallback(
    () => setIsShown((currentValue) => !currentValue),
    []
  );

  const updateImage = useCallback(
    (newImage: ArtData) => setCurrentImage(newImage),
    []
  );

  const value: ImageViewerControlsData = useMemo(
    () => ({
      isShown,
      toggleDetailDisplay,
      currentImage,
      updateImage,
    }),
    [isShown, currentImage, toggleDetailDisplay, updateImage]
  );

  return (
    <ImageViewerControlsContext.Provider value={value}>
      {children}
    </ImageViewerControlsContext.Provider>
  );
}

const useImageViewerControls = () => {
  const context = useContext(ImageViewerControlsContext);

  if (!context) throw 'ImageViewerControlsProvider is not available';

  return context;
};

export { ImageViewerControlsProvider, useImageViewerControls };
