import {
  Discord,
  DiscordAnimated,
  Gmail,
  GmailAnimated,
  Reddit,
  RedditAnimated,
  Twitch,
  TwitchAnimated,
  Twitter,
  TwitterAnimated,
} from '../images';

export const mediaLinks = [
  {
    name: 'Discord',
    href: 'https://discord.com/users/381622357904719873',
    icon: Discord,
    animatedIcon: DiscordAnimated,
    tooltip: 'Thay2son#8155',
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/thay2son',
    icon: Twitter,
    animatedIcon: TwitterAnimated,
    tooltip: '@thay2son',
  },
  {
    name: 'Twitch',
    href: 'https://twitch.tv/thay2son',
    icon: Twitch,
    animatedIcon: TwitchAnimated,
    tooltip: 'TTV thayson',
  },
  {
    name: 'Reddit',
    href: 'https://reddit.com/u/thay2son',
    icon: Reddit,
    animatedIcon: RedditAnimated,
    tooltip: '/u/thay2son',
  },
  {
    name: 'Gmail',
    href: 'mailto:thayson170@gmail.com',
    icon: Gmail,
    animatedIcon: GmailAnimated,
    tooltip: 'thayson170@gmail.com',
  },
];
