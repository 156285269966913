import { Variants } from 'framer-motion';

const growUp: Variants = {
  hover: {
    scale: 1.5,
  },
};

export default {
  growUp,
};
