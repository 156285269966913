import React from 'react';
import { Link } from 'react-router-dom';

import { ThayAstra } from '../../images';
import styles from './Header.module.scss';

function Header() {
  return (
    <div className="hstack gap-3 justify-content-center justify-content-lg-start">
      <img
        id={styles.thaysonAstraMode}
        src={ThayAstra}
        alt="Thayson's Astral Mode"
      />

      <div className="lh-1">
        <Link to="/" className="link-light">
          <h1 className="fs-4">Thay2son</h1>
          <p className="m-0 fs-5">Pixel Artist / Game Dev</p>
        </Link>
      </div>
    </div>
  );
}

export default Header;
