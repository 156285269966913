import { motion } from 'framer-motion';
import React, { useEffect } from 'react';

import {
  BouncingAvatar,
  Contacts,
  Header,
  IntroContent,
  MainLinks,
  Navbar,
} from '../../components';
import { initTooltips } from '../../utils';

import globalVariants from '../../animations/globals';

function Home() {
  useEffect(() => initTooltips(), []);

  return (
    <div className="container-fluid px-0">
      <div className="row gx-0">
        <motion.div
          className="col-12 col-lg-6 text-bg-primary"
          layoutId="sidebar"
        >
          <div className="vstack vh-100 p-4">
            <Header />

            <motion.div
              className="vstack justify-content-center align-items-center"
              initial="initial"
              animate="animate"
              variants={globalVariants.fadeIn}
            >
              <BouncingAvatar />
            </motion.div>

            <div className="vstack gap-5 justify-content-between">
              <div className="d-lg-none vstack justify-content-center">
                <MainLinks />
              </div>
              <div className="d-none d-lg-block">
                <IntroContent />
              </div>

              <Contacts />
            </div>
          </div>
        </motion.div>
        <div id="side-background" className="col-6 d-none d-lg-block pixelated">
          <Navbar />
        </div>
      </div>
    </div>
  );
}

export default Home;
