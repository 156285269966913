import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { About, ArtPortfolio, GamesPortfolio, Home } from '../pages';

const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/portfolio', element: <ArtPortfolio /> },
  { path: '/games', element: <GamesPortfolio /> },
  { path: '/about', element: <About /> },
]);

export default router;
