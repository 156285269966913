import React from 'react';
import { RouterProvider } from 'react-router-dom';

import router from './routes';

import './styles/global.scss';
import {
  ArtGalleryScrollProvider,
  ArtProvider,
  GamesProvider,
  ImageViewerControlsProvider,
} from './contexts';

function App() {
  return (
    <ArtGalleryScrollProvider>
      <ArtProvider>
        <GamesProvider>
          <ImageViewerControlsProvider>
            <RouterProvider router={router} />
          </ImageViewerControlsProvider>
        </GamesProvider>
      </ArtProvider>
    </ArtGalleryScrollProvider>
  );
}

export default App;
